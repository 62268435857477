<template>
  <div class="card">

    <div class="program-toggle">
      <b-form-checkbox v-if="data.is_approved" :value="data.is_approved" :checked="!data.is_disabled"
        @change="toggleProgramStatus(data._id, data.is_disabled)" switch></b-form-checkbox>
      <span v-if="iscurrentUserAdmin && data.can_delete" class="delete-prg-btn" @click="deleteProgram(data)"><i
          class="ri-delete-bin-5-fill"></i></span>
    </div>
        <router-link :to="{
          name: 'viewProgramDetails',
          query: { id: data._id },
        }">
      <img style="height: 148px; width: 100%; object-fit: cover" :src="data.banner" alt="Banner" srcset="" />

      <div class="featured-badge" v-if="data.is_featured">
        <img src="@/assets/images/featured.png" alt="Featured" srcset="" />
      </div>



      <h6 style="
                          margin-top: -44px;
                          padding-left: 12px;
                          padding-top: 4px;
                          padding-right: 4px;
                          height: 44px;

                          background-color: rgba(0, 0, 0, 0.5);
                          font-weight: 500;
                          font-size: 0.9rem;
                        ">

        <div class="status-dot" :style="
          data.is_approved
            ? { 'background-color': data.is_disabled ? '#ff2424' : '#74fd74' }
            : { 'background-color': 'orange' }
        "></div>
        <span style="color: #fff !important">{{
          data.name | truncate(32)
        }}</span>
      </h6>
      <div class="pb-3 pl-3">
        <div>
          <strong>{{ data.tribe_id.name }}</strong>
          <div v-if="iscurrentUserAdmin">
            {{ data.vendor_id.company_name }}
          </div>
        </div>
        <div v-if="!iscurrentUserAdmin && !data.is_approved" class="verification-label">
          Pending for verification
        </div>
      </div>
    </router-link>

  </div>
</template>

<script>
import { program } from "@/config/api/programs";
import capitalizeFirstLetter from "@/utils/capitalize";
export default {
  props: ["programData"],
  data() {
    return {
      capitalizeFirstLetter,
    };
  },
  computed: {
    data() {
      return this.programData;
    },

    userType() {
      return this.$store.getters["auth/userType"];
    },

    iscurrentUserAdmin() {
      return this.$store.getters["auth/userType"] === "admin";
    },
  },
  methods: {
    toggleProgramStatus(id, is_disabled) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to ${is_disabled ? "enable" : "disable"}?`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((result) => {
          if (result) {
            const data = {
              type: capitalizeFirstLetter(this.userType),
              programId: id,
              is_disabled: !is_disabled,
            };
            const api = program.disableProgram;
            api.data = data;
            this.changingStatus = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Program status updated.", {
                  title: "Program status",
                  variant: "success",
                  solid: true,
                });
                this.$emit("reloadData");
              })
              .catch(() => {
                this.$bvToast.toast("Program status update failed!.", {
                  title: "Program status",
                  variant: "danger",
                  solid: true,
                });
                this.$emit("reloadData");
              })
              .finally(() => {
                this.changingStatus = false;
              });
          } else {
            this.$emit("reloadData");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emit("reload");
        });
    },
    deleteProgram(data) {
      console.log(data);
      this.$bvModal
        .msgBoxConfirm(`Do you want to Delete, ${data.name}?`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const api = program.deleteProgram;
            api.data = {
              programId: data._id,
            };

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Program Deleted.", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.$emit("reloadData");
              })
              .catch(() => {
                this.$bvToast.toast("Program deletion failed!.", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
              })
              .finally(() => {
                //
              });
          }
        });
    },
  },
};
</script>

<style>
.verification-label {
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 2px;
  text-align: center;
  font-weight: 200;
}

.program-toggle {
  position: absolute;
  top: 4px;
  right: 0;
}

.featured-badge {
  position: absolute;
  top: 4px;
  left: 8px;
}

.status-dot {
  width: 12px;
  height: 12px;

  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.featured-badge img {
  width: 24px;
}

.delete-prg-btn {
  background-color: #fff;
  height: 22px;
  width: 22px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 4px;
}

.delete-prg-btn:hover {
  box-shadow: 0px 0px 5px 0px #e83e8c;
  -webkit-box-shadow: 0px 0px 5px 0px #e83e8c;
  -moz-box-shadow: 0px 0px 5px 0px #e83e8c;
  cursor: pointer;
}
</style>
